import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";

import useAxiosPrivate from "../../../common/useAxiosPrivate";

function ChassisToBeDelivered(props: any) {

  const axiosPrivate = useAxiosPrivate();

  const [chassisToBeDeliveredData, setChassisToBeDeliveredData] = useState<any>([]);

  // const getAllData = async () => {
  //   let deliveryQueues = [];
   
  //     const deliveryQueueResponse = await axiosPrivate.get(
  //       "get_all_chassis_delivery_queues"
  //     );
  //     deliveryQueues = deliveryQueueResponse.data;
  //     const numberOfRemainingDeliveryQueueItems = 50 - deliveryQueues.length;
  //     const generatedDeliveryQueueItems = Array.from(
  //       { length: numberOfRemainingDeliveryQueueItems },
  //       (v, i) => {
  //         let a = {
  //           id: null,
  //           chassisNo: null,
  //           model: null,
  //           dealer: null,
  //           notes: null,
  //           priorityNo: null,
  //           duplicate: null,
  //         };
  //         return a;
  //       }
  //     );
  //     deliveryQueues = deliveryQueues.concat(generatedDeliveryQueueItems);
  //     setChassisToBeDeliveredData(deliveryQueues);

  // };

  const getAllData = async () => {
  try {
    let deliveryQueues = [];
    const deliveryQueueResponse = await axiosPrivate.get(
      "get_all_chassis_delivery_queues"
    );
    deliveryQueues = deliveryQueueResponse.data;

    if (!Array.isArray(deliveryQueues)) {
      console.error("Unexpected response format:", deliveryQueueResponse.data);
      return;
    }

    const numberOfRemainingDeliveryQueueItems = 50 - deliveryQueues.length;
    const generatedDeliveryQueueItems = Array.from(
      { length: numberOfRemainingDeliveryQueueItems },
      () => ({
        id: null,
        chassisNo: null,
        model: null,
        dealer: null,
        notes: null,
        priorityNo: null,
        duplicate: null,
      })
    );

    deliveryQueues = deliveryQueues.concat(generatedDeliveryQueueItems);
    setChassisToBeDeliveredData(deliveryQueues);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  React.useEffect(() => {
    getAllData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2>Chassis To Be Delivered</h2>
      <TableContainer component={Paper}>
        <Table size="small" className="p-0">
          <TableHead>
            <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
              <TableCell style={{ width: "50px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Sl No
                </Typography>
              </TableCell>
              <TableCell style={{ width: "50px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Chassis No
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {chassisToBeDeliveredData?.length > 0 ? (
              chassisToBeDeliveredData.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "black",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      {i + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "black",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      {item.chassisNo}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody> */}
          <TableBody>
            {Array.isArray(chassisToBeDeliveredData) &&
            chassisToBeDeliveredData.length > 0 ? (
              chassisToBeDeliveredData.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "black",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      {i + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "black",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      {item.chassisNo || ""}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ChassisToBeDelivered;
