import {
  IInStockAndDeliveredUpdateModel,
} from "../../../generated/generated-proxies";
import { ITableColumn } from "./dailyReview-models";
import DailyReviewService from "./dailyReview-service";
import _ from "lodash";

export class DailyReviewPresenter {
  private axiosPrivate: any = null;
  private dailyReviewServiceProvider: DailyReviewService;
  constructor(axiosPrivate: any) {
    this.axiosPrivate = axiosPrivate;
    this.dailyReviewServiceProvider = new DailyReviewService(this.axiosPrivate);
  }
  assemblyTableHeader: ITableColumn[] = [
    { col_name: "Station Name", width: "180px" },
    { col_name: "Priority No", width: "85px" },
    { col_name: "Chassis No", width: "90px" },
    { col_name: "Station Alert", width: "120px" },
    { col_name: "Model", width: "185px" },
    { col_name: "Notes", width: "210px" },
    { col_name: "Action", width: "90px" },
  ];
  //----------------- Assembly ------------------

  getAllData = async (axiosPrivate: any, lineVal: string) => {
    let deliveryQueues = [];
      if (lineVal === "main-line") {
        const deliveryQueueResponse = await axiosPrivate.get(
          "get_all_chassis_delivery_queues"
        );
        deliveryQueues = deliveryQueueResponse.data;
        const numberOfRemainingDeliveryQueueItems = 50 - deliveryQueues.length;
        const generatedDeliveryQueueItems = Array.from(
          { length: numberOfRemainingDeliveryQueueItems },
          (v, i) => {
            let a = {
              id: null,
              chassisNo: null,
              model: null,
              dealer: null,
              notes: null,
              priorityNo: null,
              duplicate: null,
            };
            return a;
          }
        );
        deliveryQueues = deliveryQueues.concat(generatedDeliveryQueueItems);
      }

      const line = _.first(lineVal.split("-"));
      const arrivalQueueResponse = await axiosPrivate.get(
        `get_chassis_arrival_queues_by_line/${line}`
      );
      let arrivalQueues = arrivalQueueResponse.data;
      const numberOfRemainingArrivalQueueItems = 20 - arrivalQueues.length;
      const generatedArrivalQueueItems = Array.from(
        { length: numberOfRemainingArrivalQueueItems },
        (v, i) => {
          let a = {
            id: null,
            chassisNo: null,
            model: null,
            dealer: null,
            notes: null,
            priorityNo: null,
            duplicate: null,
          };
          return a;
        }
      );
      arrivalQueues = arrivalQueues.concat(generatedArrivalQueueItems);
      let allData = {
        "Chassis to be Delivered": deliveryQueues,
        "Chassis Arrived": arrivalQueues,
      };
      return allData;
  }

  formatCompletedItemsData = (data: any) => {
    let text = "";
    data.forEach((item: any) => {
      text += item?.priorityNo + ")" + item?.chassisNo + ", ";
    });
    if (text.endsWith(", ")) {
      text = text.slice(0, -2);
    }
    return text;
  };

  parseProductionReviewResponse = (data: any) => {
    const parsedData = data.map((str: any) => {
      try {
        return JSON.parse(str.replace(/'/g, '"'));
      } catch (error) {
        console.error("Error parsing JSON string:", error);
        return [];
      }
    });
    return parsedData;
  };

  groupRowsByStation = (data: any) => {
    const groupedData: any = {};
    data.forEach((row: any) => {
      const stationName = row.station_name;
      if (!groupedData[stationName]) {
        groupedData[stationName] = [];
      }
      groupedData[stationName].push(row);
    });
    return groupedData;
  };

  prepareProductionReviewData(data: any) {
    if (Array.isArray(data) && data) {
      const parsedContent = this.parseProductionReviewResponse(data);
      const filteredContent = parsedContent.filter(
        (item: any) => item !== null
      );
      const groupedData = this.groupRowsByStation(filteredContent);
      return groupedData;
    } else {
      return [];
    }
  }

  async fetchProductionReviewData(lineValue: string) {
    const response =
      await this.dailyReviewServiceProvider.fetchProductionReviewData(
        lineValue
      );
    return response;
    // console.log(this.prepareProductionReviewData(response.response_content));
    // return this.prepareProductionReviewData(response.response_content);
  }

  async fetchCompletedItems(lineValue: string) {
    const response = await this.dailyReviewServiceProvider.fetchCompletedItems(
      lineValue
    );
    const formatData = this.formatCompletedItemsData(response);
    return formatData;
  }

  async fetchSimulatedData(lineValue: string) {
    const response = await this.dailyReviewServiceProvider.fetchSimulatedData(
      lineValue
    );
    return response;
  }

  async updateSimulatorJsonData(
    lineVal: string,
    rowName: string,
    data: IInStockAndDeliveredUpdateModel
  ) {
    const response =
      await this.dailyReviewServiceProvider.updateSimulatorJsonData(
        lineVal,
        rowName,
        data
      );
    return response;
  }

  async updateProcessedData(id: any, reqBody: any) {
    const response = await this.dailyReviewServiceProvider.updateProcessedData(
      id,
      reqBody
    );
    return response;
  }

  // -------------- Preparation --------------------
  async getPdfUrl(pdfUrl: string) {
    const blob = await this.dailyReviewServiceProvider.fetchPdfUrlToBlob(
      pdfUrl
    );
    this.openPdfInNewWindow(blob);
  }

  getAllPrepData = async(axiosPrivate: any) => {
    const result = await axiosPrivate.get(
      `get_all_chassis_preparation_queue_report`
    );

    let formattedData: any = {};
    _.forIn(
      _.groupBy(result.data, (d: any) => {
        return d.stationName;
      }),
      function (value, key) {
        let obj: any = {};
        _.each(value, (v) => {
          if (
            !v.chassisPreparationQueues ||
            v.chassisPreparationQueues.length === 0
          ) {
            obj[v.line] = [
              {
                id: "",
                chassisNo: "",
                checked: false,
                model: "",
                priorityNo: "",
                stationAlert: "",
              },
            ];
          } else {
            obj[v.line] = _.map(v.chassisPreparationQueues, (q) => {
              return {
                id: v.id,
                chassisNo: q.chassisNo,
                checked: q.checked,
                model: q.model,
                priorityNo: q.priorityNo,
                stationAlert: q.stationAlert,
              };
            });
          }
        });
        formattedData[key] = [obj];
      }
    );
    return formattedData;
  }

  openPdfInNewWindow(blob: any) {
    const url = window.URL.createObjectURL(blob);
    const newWindow = window.open(url);
    if (!newWindow) return;
    newWindow.onload = function () {
      newWindow.print();
    };
  }
}
