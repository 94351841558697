import { useState } from "react";
import useAxiosPrivate from "../../../common/useAxiosPrivate";
import ChassisTable from "../../dailyReview/components/ChassisTable/ChassisTable";
import React from "react";

function ProductionTargetSequence() {
  const axiosPrivate = useAxiosPrivate();
  const [chassisData, setChassisData] = useState([]);
  // const [crvChassisData, setCrvChassisData] = useState([]);
  const [prodTargetVal, setProdTargetVal] = useState("");

  const getChassisPreparationData = async () => {
    const mainResponse = await axiosPrivate.get(
      `get_chassis_preparation_queues_by_line/main`
    );
    // const crvResponse = await axiosPrivate.get(
    //   `get_chassis_preparation_queues_by_line/crv`
    // );
    setChassisData(mainResponse.data);
    // setCrvChassisData(crvResponse.data);
  };

  const fetchProdTargetVal = async () => {
    const line = "main";
    const response = await axiosPrivate.get(
      `get_weekly_target_by_line/${line}`
    );
    setProdTargetVal(response.data);
  };

  React.useEffect(() => {
    fetchProdTargetVal();
    getChassisPreparationData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2>Production Target</h2>
      <ChassisTable
        chassisData={chassisData}
        productionTargetVal={prodTargetVal}
      />
      {/* <TableContainer component={Paper}>
        <Table size="small" className="p-0">
          <TableHead>
            <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
              <TableCell style={{ width: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Sl No
                </Typography>
              </TableCell>
              <TableCell style={{ width: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Chassis No
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.seqData.map((item, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    {i + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    {item}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
}
export default ProductionTargetSequence;
