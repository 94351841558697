import React from "react";
import "./productionReviewSttingStyle.css";
import ProductionTargetConfig from "./ProductionTargetConfig";
import PreparationSectionConfig from "./PreparationSectionConfig";
import ProductionCycleConfig from "./ProductionCycleConfig";

const ProductionReviewSetting = () => {
  const [activeTab, setActiveTab] = React.useState("prodTargetConfigTab"); // Active tab state

  return (
    <div className="container mt-4">
      <h3 className="text-center pb-2">Production Review Configurations</h3>
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "prodTargetConfigTab" ? "active" : ""}`}
          onClick={() => setActiveTab("prodTargetConfigTab")}
        >
          Production Target
        </button>
        <button
          className={`tab-button ${activeTab === "preparationSectionConfigTab" ? "active" : ""}`}
          onClick={() => setActiveTab("preparationSectionConfigTab")}
        >
          Preparation Section
        </button>
        <button
          className={`tab-button ${activeTab === "productionCycleConfigTab" ? "active" : ""}`}
          onClick={() => setActiveTab("productionCycleConfigTab")}
        >
          Production Cycle
        </button>
      </div>
      {activeTab === "prodTargetConfigTab" && <ProductionTargetConfig />}
      {activeTab === "preparationSectionConfigTab" && <PreparationSectionConfig />}
      {activeTab === "productionCycleConfigTab" && <ProductionCycleConfig />}
    </div>
  );
};

export default ProductionReviewSetting;
