/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_upload_daily_prod_schedule_file_upload_daily_prod_schedule_file_post */
export interface IBodyUploadDailyProdScheduleFileUploadDailyProdScheduleFilePost {
  /**
   * File Upload
   * @format binary
   */
  file_upload?: File;
}

/** Body_upload_file_upload_file_post */
export interface IBodyUploadFileUploadFilePost {
  /**
   * File Upload
   * @format binary
   */
  file_upload?: File;
}

/** CustomModel */
export interface ICustomModel {
  /** Chassisno */
  chassisNo: string;
  /** Station Name */
  station_name: string;
  /** Is Completed */
  is_completed: string;
}

/** HTTPValidationError */
export interface IHTTPValidationError {
  /** Detail */
  detail?: IValidationError[];
}

/** InStockAndDeliveredUpdateModel */
export interface IInStockAndDeliveredUpdateModel {
  /** Index */
  index: number;
  /**
   * Priorityno
   * @default ""
   */
  priorityNo?: string | null;
  /**
   * Chassisno
   * @default ""
   */
  chassisNo?: string | null;
  /**
   * Model
   * @default ""
   */
  model?: string | null;
  /**
   * Dealer
   * @default ""
   */
  dealer?: string | null;
  /**
   * Notes
   * @default ""
   */
  notes?: string | null;
  /**
   * Duplicate
   * @default false
   */
  duplicate?: boolean | null;
}

/** JobListModel */
export interface IJobListModel {
  /** Sectionname */
  SectionName: string;
  /** Startno */
  StartNo: string;
  /** Quantity */
  Quantity: number;
}

/** LinePrepModel */
export interface ILinePrepModel {
  /** Index */
  index: number;
  /** Chassisno */
  chassisNo: string;
}

/** MattressJobListModel */
export interface IMattressJobListModel {
  /** Startno */
  StartNo: string;
  /** Quantity */
  Quantity: number;
}

/** NotesModel */
export interface INotesModel {
  /** Chassisno */
  chassisNo: string;
  /** Notes */
  notes: string;
}

/** OnHoldModel */
export interface IOnHoldModel {
  /** Stationname */
  stationName: string;
  /** Chassisno */
  chassisNo: string;
  /** Model */
  model: string;
  /** Dealer */
  dealer: string;
  /** Notes */
  notes: string;
  /** Is Completed */
  is_completed: string;
  /** Is Onhold */
  is_onhold: string;
}

/** PickListModel */
export interface IPickListModel {
  /** Chassisno */
  ChassisNo: string;
}

/** ReOrderModel */
export interface IReOrderModel {
  /** Fromitem */
  fromItem: any[];
  /** Toitem */
  toItem: any[];
}

/** ScheduleGridModel */
export interface IScheduleGridModel {
  /**
   * Createddate
   * @format date-time
   */
  createdDate: string;
  /** Weeknumber */
  weekNumber: string | null;
  /** Rowid */
  rowId: string | null;
  /** Productiondate */
  productionDate: string | null;
  /** Jobid */
  jobId: string;
  /** Chassisno */
  chassisNo: string | null;
  /** Customername */
  customerName: string | null;
  /** Model */
  model: string | null;
  /** Dealer */
  dealer: string | null;
  /** Status */
  status: string | null;
  /** Completiondate */
  completionDate: string | null;
  /** Notes */
  notes: string | null;
  /** Productionvalue */
  productionValue: string | null;
  /** Chassisnotes */
  chassisNotes: string | null;
  /** Electricalsystem */
  electricalSystem: string | null;
  /** Upgradepack */
  upgradePack: string | null;
  /** Drawnby */
  drawnBy: string | null;
  /** Drawndate */
  drawnDate: string | null;
  /** Schedulesubstatus */
  scheduleSubStatus: string | null;
  /** Islocked */
  isLocked: boolean | null;
  /** Reservationval */
  reservationVal: string | null;
  /** Isreserved */
  isReserved: boolean | null;
  /**
   * Orderviewlink
   * @default ""
   */
  orderViewLink?: string | null;
}

/** SwapOptionsRequest */
export interface ISwapOptionsRequest {
  /** Textvalues */
  textValues: object;
  /** Rowid */
  rowId: number;
  /** Selectedline */
  selectedLine: string;
  /** Fromchassisno */
  fromChassisNo: string;
}

/** UpdateCheckBoxModel */
export interface IUpdateCheckBoxModel {
  /** Station Name */
  station_name: string;
  /** Line Type */
  line_type: string;
  /** Chassisno */
  chassisNo: string;
  /** Model */
  model: string;
  /** Dealer */
  dealer: string;
  /** Notes */
  notes: string;
  /** Ischecked */
  isChecked: boolean;
}

/** UpdateConfigurationInfo */
export interface IUpdateConfigurationInfo {
  /** Monday */
  Monday: string;
  /** Tuesday */
  Tuesday: string;
  /** Wednesday */
  Wednesday: string;
  /** Thursday */
  Thursday: string;
  /** Friday */
  Friday: string;
  /** Job Progress Per Station */
  Job_Progress_per_Station: string;
  /** Wall Prep Start No */
  Wall_prep_Start_No: string;
  /** Roofs Floor Lino Assembly */
  Roofs_Floor_Lino_Assembly: string;
  /** Doors Start No */
  Doors_Start_No: string;
  /** Furniture Start No */
  Furniture_Start_No: string;
  /** Cnc Start No */
  CNC_Start_No: string;
  /** Main Line Daily Production Schedule Excel Path */
  Main_Line_Daily_Production_Schedule_Excel_Path: string;
  /** File Save At */
  File_save_at: string;
  /** Items Per Page */
  Items_per_page: string;
}

/** UpdateProcessDataModel */
export interface IUpdateProcessDataModel {
  /** Chassisno */
  chassisNo: string;
  /** Station Name */
  station_name: string;
  /** Is Completed */
  is_completed: string;
}

/** UpdateScheduleSubStatusModel */
export interface IUpdateScheduleSubStatusModel {
  /** Jobid */
  jobId: string;
  /** Schedulesubstatus */
  scheduleSubStatus: string;
}

/** ValidationError */
export interface IValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  mainLineStatus = {
    /**
     * No description
     *
     * @name UploadRouteMainLineStatusLineViewGet
     * @summary Upload Route
     * @request GET:/main-line-status/{line_view}
     */
    uploadRouteMainLineStatusLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/main-line-status/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  uploadFile = {
    /**
     * No description
     *
     * @name UploadFileUploadFilePost
     * @summary Upload File
     * @request POST:/upload-file
     */
    uploadFileUploadFilePost: (data: IBodyUploadFileUploadFilePost, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/upload-file`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @name GetFilesFilesGet
     * @summary Get Files
     * @request GET:/files
     */
    getFilesFilesGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/files`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getFiles = {
    /**
     * No description
     *
     * @name GetFilesGetFilesGet
     * @summary Get Files
     * @request GET:/get-files
     */
    getFilesGetFilesGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get-files`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  outputDirectory = {
    /**
     * No description
     *
     * @name DownloadFileOutputDirectoryFileNameGet
     * @summary Download File
     * @request GET:/outputDirectory/{file_name}
     */
    downloadFileOutputDirectoryFileNameGet: (fileName: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/outputDirectory/${fileName}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getExcelDataByChassis = {
    /**
     * No description
     *
     * @name GetChassisDataFromExcelGetExcelDataByChassisChassisNoGet
     * @summary Get Chassis Data From Excel
     * @request GET:/get_excel_data_by_chassis/{chassis_no}
     */
    getChassisDataFromExcelGetExcelDataByChassisChassisNoGet: (chassisNo: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_excel_data_by_chassis/${chassisNo}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getExcelDataByChassisTwo = {
    /**
     * No description
     *
     * @name GetChassisDataFromDbTwoGetExcelDataByChassisTwoChassisNoGet
     * @summary Get Chassis Data From Db Two
     * @request GET:/get_excel_data_by_chassis_two/{chassis_no}
     */
    getChassisDataFromDbTwoGetExcelDataByChassisTwoChassisNoGet: (chassisNo: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_excel_data_by_chassis_two/${chassisNo}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateStationData = {
    /**
     * No description
     *
     * @name UpdateStationDataUpdateStationDataPost
     * @summary Update Station Data
     * @request POST:/update_station_data
     */
    updateStationDataUpdateStationDataPost: (data: ICustomModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_station_data`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  updateChassisOnHold = {
    /**
     * No description
     *
     * @name UpdateChassisOnHoldUpdateChassisOnHoldPost
     * @summary Update Chassis On Hold
     * @request POST:/update_chassis_on_hold
     */
    updateChassisOnHoldUpdateChassisOnHoldPost: (data: IOnHoldModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_chassis_on_hold`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  updateChassisOnHoldTwo = {
    /**
     * No description
     *
     * @name UpdateChassisOnHoldTwoUpdateChassisOnHoldTwoPost
     * @summary Update Chassis On Hold Two
     * @request POST:/update_chassis_on_hold_two
     */
    updateChassisOnHoldTwoUpdateChassisOnHoldTwoPost: (data: IOnHoldModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_chassis_on_hold_two`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  chassisPreparationDataLineName = {
    /**
     * No description
     *
     * @tags Production Review Common
     * @name PreparationDataChassisPreparationDataLineNameGet
     * @summary Preparation Data
     * @request GET:/chassis_preparation_data{line_name}
     */
    preparationDataChassisPreparationDataLineNameGet: (lineName: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/chassis_preparation_data${lineName}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getChassisPreparationData = {
    /**
     * No description
     *
     * @tags Production Review Common
     * @name GetPreparationDataJsonGetChassisPreparationDataGet
     * @summary Get Preparation Data Json
     * @request GET:/get_chassis_preparation_data
     */
    getPreparationDataJsonGetChassisPreparationDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_chassis_preparation_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getCrvChassisPreparationData = {
    /**
     * No description
     *
     * @tags Production Review Common
     * @name GetPreparationDataJsonGetCrvChassisPreparationDataGet
     * @summary Get Preparation Data Json
     * @request GET:/get_crv_chassis_preparation_data
     */
    getPreparationDataJsonGetCrvChassisPreparationDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_crv_chassis_preparation_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateLinePreparation = {
    /**
     * No description
     *
     * @tags Production Review Common
     * @name UpdateLinePreparationUpdateLinePreparationLineViewPost
     * @summary Update Line Preparation
     * @request POST:/update_line_preparation/{line_view}
     */
    updateLinePreparationUpdateLinePreparationLineViewPost: (
      lineView: string,
      data: ILinePrepModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_line_preparation/${lineView}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  simulatorJsonData = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name GetAllSimulatorJsonDataSimulatorJsonDataLineViewGet
     * @summary Get All Simulator Json Data
     * @request GET:/simulator_json_data/{line_view}
     */
    getAllSimulatorJsonDataSimulatorJsonDataLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/simulator_json_data/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateSimulatorJsonData = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name UpdateSimulatorJsonDataUpdateSimulatorJsonDataLineViewRowNamePut
     * @summary Update Simulator Json Data
     * @request PUT:/update_simulator_json_data/{line_view}/{row_name}
     */
    updateSimulatorJsonDataUpdateSimulatorJsonDataLineViewRowNamePut: (
      lineView: string,
      rowName: string,
      data: IInStockAndDeliveredUpdateModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_simulator_json_data/${lineView}/${rowName}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getProductionTargetVal = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name GetProductionTargetValGetProductionTargetValLineViewGet
     * @summary Get Production Target Val
     * @request GET:/get_production_target_val/{line_view}
     */
    getProductionTargetValGetProductionTargetValLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_production_target_val/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  checkDuplicateChassis = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name CheckDuplicateChassisCheckDuplicateChassisLineViewChassisNoGet
     * @summary Check Duplicate Chassis
     * @request GET:/check_duplicate_chassis/{line_view}/{chassis_no}
     */
    checkDuplicateChassisCheckDuplicateChassisLineViewChassisNoGet: (
      lineView: string,
      chassisNo: string,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/check_duplicate_chassis/${lineView}/${chassisNo}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateProcessedData = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name UpdateProcessDataUpdateProcessedDataLineViewPost
     * @summary Update Process Data
     * @request POST:/update_processed_data/{line_view}
     */
    updateProcessDataUpdateProcessedDataLineViewPost: (
      lineView: string,
      data: IUpdateProcessDataModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_processed_data/${lineView}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetResponseData = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name ResetResponseDataResetResponseDataLineViewGet
     * @summary Reset Response Data
     * @request GET:/reset_response_data/{line_view}
     */
    resetResponseDataResetResponseDataLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/reset_response_data/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  resetLinePreparationData = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name ResetLinePreparationDataResetLinePreparationDataLineViewGet
     * @summary Reset Line Preparation Data
     * @request GET:/reset_line_preparation_data/{line_view}
     */
    resetLinePreparationDataResetLinePreparationDataLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/reset_line_preparation_data/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  stockToChassisPrep = {
    /**
     * No description
     *
     * @tags Production Review - Assembly
     * @name StockToChassisPrepStockToChassisPrepGet
     * @summary Stock To Chassis Prep
     * @request GET:/stock_to_chassis_prep
     */
    stockToChassisPrepStockToChassisPrepGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/stock_to_chassis_prep`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  preparationData = {
    /**
     * No description
     *
     * @tags Production Review - Preparation
     * @name PreparationDataPreparationDataGet
     * @summary Preparation Data
     * @request GET:/preparation_data
     */
    preparationDataPreparationDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/preparation_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  preparationDataAll = {
    /**
     * No description
     *
     * @tags Production Review - Preparation
     * @name PreparationDataAllPreparationDataAllGet
     * @summary Preparation Data All
     * @request GET:/preparation_data_all
     */
    preparationDataAllPreparationDataAllGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/preparation_data_all`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateCheckbox = {
    /**
     * No description
     *
     * @tags Production Review - Preparation
     * @name UpdateIsCheckedValUpdateCheckboxPost
     * @summary Update Is Checked Val
     * @request POST:/update-checkbox/
     */
    updateIsCheckedValUpdateCheckboxPost: (data: IUpdateCheckBoxModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update-checkbox/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  generatePdf = {
    /**
     * No description
     *
     * @tags Production Review - Preparation
     * @name GeneratePdfGeneratePdfGet
     * @summary Generate Pdf
     * @request GET:/generate-pdf/
     */
    generatePdfGeneratePdfGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/generate-pdf/`,
        method: "GET",
        ...params,
      }),
  };
  productionScheduleData = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name ProductionScheduleDataProductionScheduleDataGet
     * @summary Production Schedule Data
     * @request GET:/production_schedule_data
     */
    productionScheduleDataProductionScheduleDataGet: (
      query: {
        /** Line View */
        line_view: string;
        /** Query */
        query?: string | null;
        /**
         * Page Num
         * @default 1
         */
        page_num?: number;
        /**
         * Page Size
         * @default 10000
         */
        page_size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/production_schedule_data`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  saveScheduleData = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name SaveScheduleDataModelSaveScheduleDataLinePost
     * @summary Save Schedule Data Model
     * @request POST:/save_schedule_data/{line}
     */
    saveScheduleDataModelSaveScheduleDataLinePost: (
      line: string,
      data: IScheduleGridModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/save_schedule_data/${line}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  updateScheduleData = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name UpdateScheduleDataUpdateScheduleDataLinePost
     * @summary Update Schedule Data
     * @request POST:/update_schedule_data/{line}
     */
    updateScheduleDataUpdateScheduleDataLinePost: (line: string, data: object[], params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_schedule_data/${line}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  saveReorderDataToDb = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name SaveReorderDataToDbSaveReorderDataToDbPost
     * @summary Save Reorder Data To Db
     * @request POST:/save_reorder_data_to_db
     */
    saveReorderDataToDbSaveReorderDataToDbPost: (data: IReOrderModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/save_reorder_data_to_db`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  updateScheduleSubstatus = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name UpdateScheduleSubStatusUpdateScheduleSubstatusPut
     * @summary Update Schedule Sub Status
     * @request PUT:/update_schedule_substatus
     */
    updateScheduleSubStatusUpdateScheduleSubstatusPut: (
      data: IUpdateScheduleSubStatusModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_schedule_substatus`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  rearrangeScheduleData = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name RearrangeScheduleDataRearrangeScheduleDataLineGet
     * @summary Rearrange Schedule Data
     * @request GET:/rearrange_schedule_data/{line}
     */
    rearrangeScheduleDataRearrangeScheduleDataLineGet: (line: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/rearrange_schedule_data/${line}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  removeReservedItem = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name RemoveReservedItemRemoveReservedItemLineNameRowIdGet
     * @summary Remove Reserved Item
     * @request GET:/remove_reserved_item/{line_name}/{row_id}
     */
    removeReservedItemRemoveReservedItemLineNameRowIdGet: (
      lineName: string,
      rowId: number,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/remove_reserved_item/${lineName}/${rowId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getscheduledates = {
    /**
     * No description
     *
     * @tags Production Schedule
     * @name GetScheduleSlotsGetscheduledatesPost
     * @summary Get Schedule Slots
     * @request POST:/getscheduledates
     */
    getScheduleSlotsGetscheduledatesPost: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/getscheduledates`,
        method: "POST",
        ...params,
      }),
  };
  swapOptionsUpdate = {
    /**
     * No description
     *
     * @name ReceiveSwapOptionsSwapOptionsUpdatePost
     * @summary Receive Swap Options
     * @request POST:/swap_options_update
     */
    receiveSwapOptionsSwapOptionsUpdatePost: (data: ISwapOptionsRequest, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/swap_options_update`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getConfigurationInfo = {
    /**
     * No description
     *
     * @tags Configuration
     * @name GetProductionInfoGetConfigurationInfoGet
     * @summary Get Production Info
     * @request GET:/get_configuration_info
     */
    getProductionInfoGetConfigurationInfoGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_configuration_info`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateConfigurationInfo = {
    /**
     * No description
     *
     * @tags Configuration
     * @name UpdateConfigurationInfoUpdateConfigurationInfoPost
     * @summary Update Configuration Info
     * @request POST:/update_configuration_info
     */
    updateConfigurationInfoUpdateConfigurationInfoPost: (data: IUpdateConfigurationInfo, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_configuration_info`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getMainLineConfigurationData = {
    /**
     * No description
     *
     * @tags Configuration
     * @name GetConfigurationDataAsJsonGetMainLineConfigurationDataGet
     * @summary Get Configuration Data As Json
     * @request GET:/get_main_line_configuration_data
     */
    getConfigurationDataAsJsonGetMainLineConfigurationDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_main_line_configuration_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateMainLineConfigurationData = {
    /**
     * No description
     *
     * @tags Configuration
     * @name UpdateConfigurationDataUpdateMainLineConfigurationDataPost
     * @summary Update Configuration Data
     * @request POST:/update_main_line_configuration_data
     */
    updateConfigurationDataUpdateMainLineConfigurationDataPost: (data: object, params: RequestParams = {}) =>
      this.request<Record<string, string>, IHTTPValidationError>({
        path: `/update_main_line_configuration_data`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getCrvLineConfigurationData = {
    /**
     * No description
     *
     * @tags Configuration
     * @name GetCrvConfigurationDataGetCrvLineConfigurationDataGet
     * @summary Get Crv Configuration Data
     * @request GET:/get_crv_line_configuration_data
     */
    getCrvConfigurationDataGetCrvLineConfigurationDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_crv_line_configuration_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateCrvLineConfigurationData = {
    /**
     * No description
     *
     * @tags Configuration
     * @name UpdateCrvConfigurationDataUpdateCrvLineConfigurationDataPost
     * @summary Update Crv Configuration Data
     * @request POST:/update_crv_line_configuration_data
     */
    updateCrvConfigurationDataUpdateCrvLineConfigurationDataPost: (data: object, params: RequestParams = {}) =>
      this.request<Record<string, string>, IHTTPValidationError>({
        path: `/update_crv_line_configuration_data`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  readCotsPickXml = {
    /**
     * No description
     *
     * @tags Pick List
     * @name ReadCotsPickXmlReadCotsPickXmlPost
     * @summary Read Cots Pick Xml
     * @request POST:/read_cots_pick_xml
     */
    readCotsPickXmlReadCotsPickXmlPost: (data: IPickListModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/read_cots_pick_xml`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  uploadDailyProdScheduleFile = {
    /**
     * No description
     *
     * @tags Pick List
     * @name UploadDailyProdScheduleFileUploadDailyProdScheduleFilePost
     * @summary Upload Daily Prod Schedule File
     * @request POST:/upload_daily_prod_schedule_file
     */
    uploadDailyProdScheduleFileUploadDailyProdScheduleFilePost: (
      data: IBodyUploadDailyProdScheduleFileUploadDailyProdScheduleFilePost,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/upload_daily_prod_schedule_file`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  getDailyProdScheduleFilename = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetDailyProdScheduleFilenameGetDailyProdScheduleFilenameGet
     * @summary Get Daily Prod Schedule Filename
     * @request GET:/get_daily_prod_schedule_filename
     */
    getDailyProdScheduleFilenameGetDailyProdScheduleFilenameGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_daily_prod_schedule_filename`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getProductionTargetSequenceData = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetProductionTargetSequenceDataGetProductionTargetSequenceDataGet
     * @summary Get Production Target Sequence Data
     * @request GET:/get_production_target_sequence_data
     */
    getProductionTargetSequenceDataGetProductionTargetSequenceDataGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_production_target_sequence_data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getAndSaveMattressJobList = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetAndSaveMattressJobListGetAndSaveMattressJobListPost
     * @summary Get And Save Mattress Job List
     * @request POST:/get_and_save_mattress_job_list
     */
    getAndSaveMattressJobListGetAndSaveMattressJobListPost: (data: IMattressJobListModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_and_save_mattress_job_list`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getAndSaveJobList = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetAndSaveJobListGetAndSaveJobListPost
     * @summary Get And Save Job List
     * @request POST:/get_and_save_job_list
     */
    getAndSaveJobListGetAndSaveJobListPost: (data: IJobListModel, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_and_save_job_list`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getMattressJobList = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetMattressJobListGetMattressJobListGet
     * @summary Get Mattress Job List
     * @request GET:/get_mattress_job_list
     */
    getMattressJobListGetMattressJobListGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_mattress_job_list`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getChassisToDelivered = {
    /**
     * No description
     *
     * @tags Pick List
     * @name GetChassisToDeliveredDataGetChassisToDeliveredGet
     * @summary Get Chassis To Delivered Data
     * @request GET:/get_chassis_to_delivered
     */
    getChassisToDeliveredDataGetChassisToDeliveredGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_chassis_to_delivered`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  tokenMicrosoft = {
    /**
     * No description
     *
     * @name GenerateTokenTokenMicrosoftPost
     * @summary Generate Token
     * @request POST:/token_microsoft
     */
    generateTokenTokenMicrosoftPost: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/token_microsoft`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  xmlToJsonMerged = {
    /**
     * No description
     *
     * @name ConvertLatestXmlToJsonMergedXmlToJsonMergedGet
     * @summary Convert Latest Xml To Json Merged
     * @request GET:/xml_to_json_merged
     */
    convertLatestXmlToJsonMergedXmlToJsonMergedGet: (
      query?: {
        /** Query */
        query?: string | null;
        /**
         * Page Num
         * @default 1
         */
        page_num?: number;
        /**
         * Page Size
         * @default 10
         */
        page_size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/xml_to_json_merged`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  updateDataFromCots = {
    /**
     * No description
     *
     * @name UpdateDataFromCotsApiUpdateDataFromCotsGet
     * @summary Update Data From Cots Api
     * @request GET:/update_data_from_cots
     */
    updateDataFromCotsApiUpdateDataFromCotsGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/update_data_from_cots`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  mergedData = {
    /**
     * No description
     *
     * @name LatestMergedDataMergedDataGet
     * @summary Latest Merged Data
     * @request GET:/merged_data
     */
    latestMergedDataMergedDataGet: (
      query?: {
        /** Query */
        query?: string | null;
        /**
         * Page Num
         * @default 1
         */
        page_num?: number;
        /**
         * Page Size
         * @default 10
         */
        page_size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/merged_data`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  updateNotesByChassisNumber = {
    /**
     * No description
     *
     * @name UpdateNotesByChassisNumberUpdateNotesByChassisNumberChassisNumberPut
     * @summary Update Notes By Chassis Number
     * @request PUT:/update_notes_by_chassis_number/{chassis_number}
     */
    updateNotesByChassisNumberUpdateNotesByChassisNumberChassisNumberPut: (
      chassisNumber: string,
      data: INotesModel,
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/update_notes_by_chassis_number/${chassisNumber}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getDataFromInStock = {
    /**
     * No description
     *
     * @name GetDataFromInStockGetDataFromInStockGet
     * @summary Get Data From In Stock
     * @request GET:/get_data_from_in_stock
     */
    getDataFromInStockGetDataFromInStockGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/get_data_from_in_stock`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getCompletedItems = {
    /**
     * No description
     *
     * @name GetCompletedItemsGetCompletedItemsLineViewGet
     * @summary Get Completed Items
     * @request GET:/get_completed_items/{line_view}
     */
    getCompletedItemsGetCompletedItemsLineViewGet: (lineView: string, params: RequestParams = {}) =>
      this.request<any, IHTTPValidationError>({
        path: `/get_completed_items/${lineView}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  sendEmail = {
    /**
     * No description
     *
     * @name SendEmailHandlerSendEmailPost
     * @summary Send Email Handler
     * @request POST:/send-email/
     */
    sendEmailHandlerSendEmailPost: (
      query: {
        /** To */
        to: string;
        /** Subject */
        subject: string;
        /** Message */
        message: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, IHTTPValidationError>({
        path: `/send-email/`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
