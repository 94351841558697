import React from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";

const ProductionCycleConfig = () => {
  const [formData, setFormData] = React.useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = React.useState<{ [key: number]: string }>({});
  const axiosPrivate = useAxiosPrivate();

  const fieldConfig: { [key: string]: { label: string; type: string } } = {  
    shiftStartTime: { label: "Shift Start Time", type: "time" },
    shiftEndTime: { label: "Shift End Time", type: "time" },
    cycleTime: { label: "Cycle Time (Minutes)", type: "number" },
    fileSaveAt: { label: "File Save At", type: "time" },
  };

  const fetchProductionSectionConfig = async () => {
    try {
      const productionReviewSettingResponse = await axiosPrivate.get(
        `get_all_production_cycle_configs`
      );
      const data = productionReviewSettingResponse.data;
      setFormData(data);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevData: any[]) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });

    // Validate input
    if (Number(value) < 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: `${fieldConfig[name].label} cannot be negative`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  const submitData = async (event: any) => {
    event.preventDefault();
    toast.info("Request pending...", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false, 
      toastId: "requestPending",
    });
    let hasError = false;
    formData.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        if (fieldConfig[key] && Number(item[key]) < 0) {
          hasError = true;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: `${fieldConfig[key].label} cannot be negative`,
          }));
        }
      });
    });

    if (hasError) {
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.error("Invalid input. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log(formData)
    try {
      await Promise.all(
        formData.map(async (item) => {
          await axiosPrivate.put(
            `update_production_cycle_config/${item.id}`,
            item
          );
        })
      );
      toast.dismiss("requestPending"); 
      toast.success("File saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Failed to save data", error);
      toast.dismiss("requestPending"); 
      toast.error("Failed to save data...!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  React.useEffect(() => {
    fetchProductionSectionConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <div className="form_wrapper">
          <form onSubmit={submitData}>
            <div className="row">
              {formData?.map((item: any, index: any) => (
                <div className="col-6" key={item.id}>
                  <div className="form-group px-5">
                    <h3>{item.line.toUpperCase()} Line</h3>
                    {Object.keys(item)?.map(
                      (key) =>
                        key !== "id" &&
                        key !== "line" &&
                        key !== "lastCountDownTime" && (
                          <div key={key} className="mb-3">
                            <label htmlFor={`${key}-${index}`}>
                              {fieldConfig[key]?.label || key}
                            </label>
                            <input
                              type={fieldConfig[key]?.type || "text"}
                              className="form-control"
                              id={`${key}-${index}`}
                              name={key}
                              value={item[key] || ""}
                              onChange={(event) => handleChange(index, event)}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6 mx-auto mt-3">
              <input className="btn blue-btn" type="submit" value="Save" />
            </div>
            {/* <button type="submit" className="btn blue-btn">
                Submit
                </button> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductionCycleConfig;
