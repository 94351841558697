import { useState, useEffect, Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validatePriorityNo } from "./utils.js";
import "./assembly.css";
import { useSelector } from "react-redux";
import TableHeader from "../ProductionTableHeader/TableHeader";
import { DailyReviewPresenter } from "../../dailyReview-presenter";
import _ from "lodash";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";

export default function AssemblyTable({
  responseContent,
  fetchData,
  selectedLineValue,
  productionTargetVal,
  updateSimulatorData,
  layoutData,
  layoutDataCopy,
  refresh,
  getChassisPreparationQueueAndProductionLines,
}) {
  const authInfo = useSelector((state) => state.userAuthInfoState);
  // ------------------- Define Role --------------------
  const topTableValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("inventory_staff") ||
      authInfo.roles?.includes("analyst_staff"));

  const jobsTableValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("analyst_staff"));

  const editPriorityValidRole =
    authInfo.roles &&
    (authInfo.roles?.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("inventory_staff"));
  //--------------------------------------------------------------------------------------------
  const axiosPrivate = useAxiosPrivate();
  let dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
  // eslint-disable-next-line no-unused-vars
  const [editedNotes, setEditedNotes] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [layoutDataChassis, setLayoutDataChassis] = useState('');
  const [isNoteEditingInChassisArrived, setIsNoteEditingInChassisArrived] =
    useState(false);
  const [rowIndex, setRowIndex] = useState(); // This is for stations
  const [stationName, setStationName] = useState();
  const [innerRowIndex, setInnerRowIndex] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isPriorityCol, setIsPriorityCol] = useState(false);
  const [priorityVal, setPriorityVal] = useState("");
  const [completedItems, setCompletedItems] = useState("");
  const [toggleExpand, setToggleExpand] = useState(false);
  const [expandedRow, setExpandedRow] = useState();
  const [isInputField, setIsInputField] = useState(false);
  const [editingRowIndexInChassisArrived, setEditingRowIndexInChassisArrived] = useState();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
  }, []);

  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Alert", value: "Alert" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Planning", value: "Planning" },
  ];

  const fetchSimulatedData = async (lineVal) => {
    try {
      const chassisDataForLine = await dailyReviewPresenter.getAllData(axiosPrivate, lineVal);
      await updateSimulatorData(chassisDataForLine);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  };

  const fetchCompletedItems = async (lineVal) => {
    try {
      const completedItemsFormatted =
        await dailyReviewPresenter.fetchCompletedItems(lineVal);
      setCompletedItems(completedItemsFormatted);
    } catch (error) {
      console.log("Something is Wrong!", error);
    }
  };

  useEffect(() => {
    fetchSimulatedData(selectedLineValue);
    fetchCompletedItems(selectedLineValue);
    let id = setInterval(() => {
      fetchSimulatedData(selectedLineValue);
    }, 30 * 1000);
    return () => clearInterval(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineValue, refresh]);

  const handleNotesInputField = (stationName, index) => {
    setIsInputField(true);
    setRowIndex(index);
    setStationName(stationName);
  };
  const handleNotes = (event) => {
    setEditedNotes(event.target.value);
    responseContent[rowIndex].notes = event.target.value;
  };
  const handlePostNotes = async (event, station) => {
    if (event.key === "Enter") {
      setIsInputField(false);
      try {
        await toast.promise(
          axiosPrivate.put(`update_production_line/${station.id}`, station),
          {
            pending: "Request pending",
          }
        );
        fetchData();
        toast.success("Request Success");
      } catch (error) {
        console.log("error", error);
        toast.error("Invalid Input");
      }
    }
    if (event.key === "Escape") {
      setIsInputField(false);
    }
  };

  //----------------------------------------------------------------------
  const handleChassisInput = (mainRowIndex, subRowIndex, rowName) => {
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(false);
  };
  const handleChassisEdit = (rowName, event, subRowIndex) => {
    setLayoutDataChassis(event.target.value);
    layoutData[rowName][subRowIndex].chassisNo = event.target.value;
  };

  const handleGetChassisData = async (
    rowName,
    event,
    subRowIndex,
    chassisNo,
    row
  ) => {
    setIsVisible(false);
    if (event.key === "Enter") {
      setIsVisible(true);
      setIsInputField(false);

      if (chassisNo !== "") {
        try {
          const api_response = await axiosPrivate.post(
            `check_duplicate_chassis_queue/${chassisNo}`
          );
          if (api_response?.data?.isSuccess) {
            toast.error("Chassis number already exists..!");
            await fetchSimulatedData(selectedLineValue);
            return;
          }
        } catch (error) {
          console.log("Error checking duplicate chassis", error);
          return;
        }
      }
      try {
        await toast.promise(
          axiosPrivate.post("manage_chassis_queue", {
            id: row.id,
            chassisNo: chassisNo || "",
          }),
          {
            pending: "Request pending",
          }
        );
        toast.success("Request Success..! 👌");
      } catch (error) {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.detail || "Not Found!");
        }
      }
      await fetchSimulatedData(selectedLineValue);
    }
  };
  const handleOnBlur = (rowName, event, subRowIndex) => {
    if (!isVisible) {
      layoutData[rowName][subRowIndex].chassisNo =
        layoutDataCopy[rowName][subRowIndex].chassisNo;
    }
  };
  const handleOnHold = async (row) => {
    try {
      await toast.promise(
        axiosPrivate.put(`update_hold_to_production_line/${row.id}`, {
          chassisNo: row.chassisNo,
          model: row.model,
        }),
        {
          pending: "Request pending",
        }
      );
      fetchSimulatedData(selectedLineValue);
      getChassisPreparationQueueAndProductionLines();
      toast.success("Request Success");
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid Input");
    }
  };
  const handleReleaseChassis = async (row) => {
    try {
      await toast.promise(
        axiosPrivate.put(`release_chassis_to_production_line/${row.id}`),
        {
          pending: "Request pending",
        }
      );
      getChassisPreparationQueueAndProductionLines();
      toast.success("Request Success");
    } catch (error) {
      console.log("error", error);
      toast.error("Request Failed");
    }
  }
  const handleFirstLineOnHold = async (row) => {
    try {
      await toast.promise(
        axiosPrivate.post(`hold_first_production_line/${row.id}`),
        {
          pending: "Request pending",
        }
      );
      fetchSimulatedData(selectedLineValue);
      getChassisPreparationQueueAndProductionLines();
      toast.success("Chassis moved to the Chassis Arrived...!");
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid Input");
    }
  }
  //-----------------------------------------------------------
  const handlePriorityInput = (mainRowIndex, subRowIndex, rowName) => {
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(true);
    setPriorityVal(layoutData[rowName][subRowIndex].priorityNo);
  };
  const handleChangePriority = (rowName, event, subRowIndex) => {
    setPriorityVal(event.target.value);
    layoutData[rowName][subRowIndex].priorityNo = event.target.value;
  };
  const handleSetPriority = async (rowName, event, subRowIndex, row) => {
    if (event.key === "Enter") {
      setIsInputField(false);
      setIsPriorityCol(false);
      const line = _.first(selectedLineValue.split("-"));
      const isPriorityValidated = validatePriorityNo(
        priorityVal,
        productionTargetVal
      );
      layoutData[rowName][subRowIndex].priorityNo = priorityVal;
      if (priorityVal > productionTargetVal || !isPriorityValidated) {
        layoutData[rowName][subRowIndex].priorityNo = "";
        setPriorityVal("");
        toast.error("Invalid Input");
        fetchSimulatedData(selectedLineValue);
      } else {
        try {
          const api_response = await axiosPrivate.post(
            "check_duplicate_priority_no",
            { line: line, priorityNo: priorityVal }
          );
          if (api_response?.data?.isSuccess) {
            toast.error("Priority no already exists..!");
            await fetchSimulatedData(selectedLineValue);
            return;
          }
        } catch (error) {
          console.log("Error checking duplicate priority no", error);
          return;
        }
      }

      if (isPriorityValidated) {
        try {
          await toast.promise(
            axiosPrivate.put(`update_chassis_arrival_queue/${row.id}`, {
              id: row.id,
              chassisNo: row.chassisNo || "",
              priorityNo: row.priorityNo || "",
              model: row.model || "",
              stationAlert: row.stationAlert || "",
              notes: row.notes || "",
              line: line,
            }),
            {
              pending: "Request pending",
            }
          );
          toast.success("Request Success");
          fetchSimulatedData(selectedLineValue);
          // await updateSimulatorData(response[0]);
        } catch (error) {
          console.log("error", error);
          toast.error("Invalid Input");
        }
      }
    }
  };
  const handleExpandRow = (row, subRowIndex, rowName, mainRowIndex) => {
    setToggleExpand((prevState) => !prevState);
    setExpandedRow(mainRowIndex);
  };

  const handleAlertOptionsChange = async (station, value) => {
    try {
      const line = _.first(selectedLineValue.split("-"));
      let model = {
        chassisNo: station.chassisNo || "",
        stationName: station.stationName || "",
        lineNumber: station.lineNumber,
        model: station.model || "",
        stationAlert: value || "",
        notes: station.notes || "",
        priorityNo: station.priorityNo || "",
        completed: station.completed,
        hold: station.hold,
        line: line,
        startedOn: station.startedOn || null,
        completedOn: station.completedOn || null,
      };
      await toast.promise(
        axiosPrivate.put(
          `update_station_alert_of_production_line/${station?.id}`,
          model
        ),
        {
          pending: "Request pending",
        }
      );
      toast.success("Request Success..!");
      await getChassisPreparationQueueAndProductionLines();
    } catch (error) {
      console.log("Error", error.response.data.detail);
      await getChassisPreparationQueueAndProductionLines();
      toast.error(error.response.data.detail);
    }
  };

  const handleNotesEditInChassisArrived = (subRowIndex) => {
    setIsNoteEditingInChassisArrived(true);
    setEditingRowIndexInChassisArrived(subRowIndex);
    setIsPriorityCol(false);
  };

  const handleNotesInChassisArrivedOnBlur = (notes, rowName, subRowIndex) => {
    layoutData[rowName][subRowIndex].notes = notes;
    console.log("mainRowIndex - ", notes);
    console.log("data - ", layoutData);
  };

  const handleNotesInChassisArrivedSave = async (notes, row) => {
    // console.log("Row- ", row)
    setIsNoteEditingInChassisArrived(false);
    try {
      await toast.promise(
        axiosPrivate.put(`update_chassis_arrival_queue/${row.id}`, row),
        {
          pending: "Request pending",
        }
      );
      fetchSimulatedData(selectedLineValue);
      toast.success("Request Success");
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid Input");
    }
  };

  const handleNotesInChassisArrivedOnChange = (value, rowName, subRowIndex) => {
    setEditedNotes(value);
    layoutData[rowName][subRowIndex].notes = value;
  };

  const handleOpenModal = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleChassisArrivalQueueDelete = async () => {
    if (selectedId){
      console.log(selectedId);
      try {
        await toast.promise(
          axiosPrivate.delete(`delete_chassis_arrival_queue_by_id/${selectedId}`),
          {
            pending: "Request pending",
          }
        );
        fetchSimulatedData(selectedLineValue);
        toast.success("Request Success");
    } catch (error) {
      toast.error("Request Failed");
    }
    }
    handleCloseModal();
  }

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ marginTop: "8px", marginBottom: "50px"}}
      >
        <Table size="small" className="p-0">
          {/* <Header /> */}
          <TableHeader headers={dailyReviewPresenter.assemblyTableHeader} />
          <TableBody>
            {topTableValidRole && Object.keys(layoutData).length > 0 ? (
              <>
                {Object.keys(layoutData).map((rowName, mainRowIndex) => (
                  <Fragment key={mainRowIndex}>
                    {layoutData[rowName].map((row, subRowIndex) => (
                      <TableRow
                        key={subRowIndex}
                        style={{
                          backgroundColor: "#F9F9F9",
                          fontFamily: "League Spartan, sans-serif",
                        }}
                      >
                        {subRowIndex === 0 && (
                          <>
                            <TableCell
                              className="cursor-pointer"
                              rowSpan={layoutData[rowName].length}
                              onClick={() =>
                                handleExpandRow(
                                  row,
                                  subRowIndex,
                                  rowName,
                                  mainRowIndex
                                )
                              }
                              style={{
                                backgroundColor:
                                  rowName === "Chassis Arrived" &&
                                  layoutData[rowName].filter((key) => {
                                    return key.chassisNo && key.chassisNo;
                                  }).length < 7
                                    ? "#f39ba2"
                                    : "inherit",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between gap-1">
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontFamily: "League Spartan, sans-serif",
                                  }}
                                >
                                  {rowName}
                                </Typography>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="9"
                                  viewBox="0 0 16 9"
                                  fill="none"
                                >
                                  <path
                                    d="M2 2L8 8L14 2"
                                    stroke="#25282B"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </TableCell>
                            {Array(6)
                              .fill()
                              .map((_, i) => (
                                <TableCell
                                  key={i}
                                  className={`expandable-row ${
                                    toggleExpand && expandedRow === mainRowIndex
                                      ? "close"
                                      : ""
                                  }`}
                                ></TableCell>
                              ))}
                          </>
                        )}
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          style={{
                            height: "30px",
                            backgroundColor: `${
                              rowName === "Chassis to be Delivered"
                                ? "#ececec"
                                : "inherit"
                            }`,
                          }}
                          onClick={() =>
                            handlePriorityInput(
                              mainRowIndex,
                              subRowIndex,
                              rowName
                            )
                          }
                        >
                          {editPriorityValidRole &&
                          isInputField &&
                          rowName === "Chassis Arrived" &&
                          innerRowIndex === subRowIndex &&
                          isPriorityCol ? (
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              pattern="(^[1-9][0-9]*[ab]?$)?"
                              value={
                                priorityVal
                              }
                              onChange={(event) =>
                                handleChangePriority(
                                  rowName,
                                  event,
                                  subRowIndex
                                )
                              }
                              onKeyDown={(event) =>
                                handleSetPriority(
                                  rowName,
                                  event,
                                  subRowIndex,
                                  row
                                )
                              }
                              onBlur={(event) =>
                                handleOnBlur(rowName, event, subRowIndex)
                              }
                            ></input>
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row.priorityNo}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          onClick={() =>
                            handleChassisInput(
                              mainRowIndex,
                              subRowIndex,
                              rowName
                            )
                          }
                        >
                          {editPriorityValidRole &&
                          isInputField &&
                          rowName === "Chassis to be Delivered" &&
                          innerRowIndex === subRowIndex &&
                          !isPriorityCol ? (
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              value={
                                layoutData[rowName][subRowIndex]?.chassisNo
                              }
                              onChange={(event) =>
                                handleChassisEdit(rowName, event, subRowIndex)
                              }
                              onKeyDown={(event) =>
                                handleGetChassisData(
                                  rowName,
                                  event,
                                  subRowIndex,
                                  layoutData[rowName][subRowIndex]?.chassisNo,
                                  row
                                )
                              }
                              onBlur={(event) =>
                                handleOnBlur(rowName, event, subRowIndex)
                              }
                            ></input>
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row.chassisNo}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {row.model}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          onClick={() =>
                            handleNotesEditInChassisArrived(subRowIndex)
                          }
                        >
                          {isNoteEditingInChassisArrived &&
                          editingRowIndexInChassisArrived === subRowIndex &&
                          rowName === "Chassis Arrived" &&
                          !isPriorityCol &&
                          row.chassisNo ? (
                            <input
                              type="text"
                              value={
                                layoutData[rowName][subRowIndex]?.notes ?? ""
                              }
                              onChange={(e) =>
                                handleNotesInChassisArrivedOnChange(
                                  e.target.value,
                                  rowName,
                                  subRowIndex
                                )
                              }
                              onBlur={(e) =>
                                handleNotesInChassisArrivedOnBlur(
                                  e.target.value,
                                  rowName,
                                  subRowIndex
                                )
                              }
                              onKeyDown={(e) =>
                                e.key === "Enter" &&
                                handleNotesInChassisArrivedSave(
                                  e.target.value,
                                  row
                                )
                              }
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                              // Switch to edit mode on click.
                            >
                              {row.notes || ""}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={`expandable-row ${
                            toggleExpand && expandedRow === mainRowIndex
                              ? "expand"
                              : "close"
                          }`}
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          ></Typography>
                          {(rowName === "Chassis Arrived" && row.chassisNo) && (
                            <button style={{ border: 0, background: "inherit"}}
                              onClick={() => handleOpenModal(row.id)}
                            >
                              <span className="d-block" style={{ color: "red", cursor: "pointer", fontWeight: "500"}}>&#10060;</span>
                            </button>
                            )
                          }
                          
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </>
            ) : (
              <Typography
                variant="body1"
                className="loading text-start py-1 ps-3"
              >
                Data not Found
              </Typography>
            )}
            {responseContent.length > 0 ? (
              <>
                {topTableValidRole && (
                  <Fragment>
                    {responseContent.map((station, index) => (
                      <TableRow
                        key={index}
                        style={{
                          background: `${
                            index % 2 === 0 ? "#eaeae8" : "#F9F9F9"
                          }`,
                          borderTop: `${
                            index === 0 && index === responseContent.length - 1
                              ? "20px solid #ffff"
                              : "inherit"
                          }`,
                        }}
                      >
                        <TableCell className="station-name">
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {station.lineNumber.toString().padStart(2, "0")}{" "}
                            {station.stationName}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {/* Removable */}
                            {/* <Checkbox
                              disabled={!station.chassisNo}
                              checked={station.completed}
                              onChange={(event) =>
                                handleCheckBoxChecked(event, station)
                              }
                            /> */}
                            {station.chassisNo && station.priorityNo
                              ? `${station.priorityNo})${station.chassisNo}`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "League Spartan, sans-serif" }}
                          >
                            <Select
                              name="stationAlert"
                              value={station?.stationAlert ?? ""}
                              onChange={(event) =>
                                handleAlertOptionsChange(
                                  station,
                                  event.target.value
                                )
                              }
                              className="station_alert_dropdown"
                              displayEmpty
                              disabled={!station?.chassisNo}
                              inputProps={{ "aria-label": "Without label" }}
                              style={{
                                width: "100%",
                                fontFamily: "League Spartan, sans-serif",
                                backgroundColor:
                                  station?.stationAlert === "Alert"
                                    ? "#FF0000"
                                    : station?.stationAlert === "Work Active"
                                    ? "#FFA500"
                                    : station?.stationAlert === "Work Complete"
                                    ? "#008000"
                                    : station?.stationAlert === "Stores"
                                    ? "#0000FF"
                                    : station?.stationAlert === "Planning"
                                    ? "#808080"
                                    : "transparent",
                                color:
                                  station?.stationAlert === "Alert" ||
                                  station?.stationAlert === "Work Active" ||
                                  station?.stationAlert === "Work Complete" ||
                                  station?.stationAlert === "Stores" ||
                                  station?.stationAlert === "Planning"
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {stationAlertOptions.map((item) => (
                                <MenuItem
                                  value={item.value}
                                  style={{
                                    fontFamily: "League Spartan, sans-serif",
                                  }}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {station.model}
                          </Typography>
                        </TableCell>
                        <TableCell style={{height: "30px"}}
                          onClick={() =>
                            handleNotesInputField(station.stationName, index)
                          }
                        >
                          {isInputField &&
                          index === rowIndex &&
                          station.stationName === stationName ? (
                            <input
                              type="text"
                              disabled={!station.chassisNo}
                              value={station.notes ?? ""}
                              onChange={handleNotes}
                              onKeyDown={(event) =>
                                handlePostNotes(event, station)
                              }
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {station.notes}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {station?.hold ? (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                {station.holdItem?.chassisNo
                                  ? `${station.holdItem?.priorityNo})${station.holdItem?.chassisNo}`
                                  : ""}
                              </Typography>
                              {!station?.chassisNo && (
                                <button
                                  className="chassis-unhold-btn"
                                  onClick={() =>
                                    handleReleaseChassis(
                                      station
                                    )
                                  }
                                >
                                  <span className="d-block">&#8595;</span>
                                  <span className="chassis-unhold-btn--tooltip">
                                    Release the Chassis
                                  </span>
                                </button>
                              )}
                            </div>
                          ) : (
                            <>
                              {station.chassisNo && (
                                station.lineNumber === 1 ? (
                                  <>
                                    <button
                                      className="chassis-hold-btn d-flex align-items-center justify-content-center flex-column"
                                      onClick={() =>
                                        handleFirstLineOnHold(
                                          station
                                        )
                                      }
                                    >
                                      <span className="d-block">&#8593;</span>
                                      <span className="chassis-hold-btn--tooltip">
                                        Chassis on hold
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="chassis-hold-btn d-flex align-items-center justify-content-center flex-column"
                                      onClick={() =>
                                        handleOnHold(
                                          station
                                        )
                                      }
                                    >
                                      <span className="d-block">&#8593;</span>
                                      <span className="chassis-hold-btn--tooltip">
                                        Chassis on hold
                                      </span>
                                    </button>
                                  </>
                              ))}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                )}
              </>
            ) : (
              <Typography
                variant="body1"
                className="loading text-start py-1 ps-3"
              >
                Data not Found
              </Typography>
            )}
          </TableBody>
        </Table>
        {jobsTableValidRole && (
          <>
            <Table style={{ marginTop: "20px" }}>
              <TableBody>
                <TableRow className="mt-5">
                  <TableCell style={{ width: "203px" }}>
                    <Typography>JOBS FOR THE WEEK</Typography>
                  </TableCell>
                  <TableCell>{completedItems}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChassisArrivalQueueDelete} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
