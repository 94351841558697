import "./pickList-styles.css";
import ProductionTargetSequence from "./ProductionTargetSeq/ProductionTargetSequence";
import ProductionSequenceMaintain from "./ProductionSequenceMaintain/ProductionSequenceMaintain";
import ChassisToBeDelivered from "./ChassisToBeDelivered/ChassisToBeDelivered";
import { useState } from "react";

function PickList() {
  // const pickListDataPresenter = new PickListPresenter();
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOption, setSelectedOption] = useState("main-line");
  // const [file, setFile] = useState<IFileType>({ file: undefined, name: "" });
  // const [fileName, setFileName] = useState<string>("");
  // const [data, setData] = useState<string[]>([]);
  // const [deliveredData, setDeliveredData] = useState<string[]>([]);

  // async function getFileNameFromDb() {
  //   const fileName = await pickListDataPresenter.getFileName();
  //   setFileName(fileName);
  // }

  // async function getProductionTargetSeqData() {
  //   const seqData =
  //     await pickListDataPresenter.getProductionTargetSeqFromFile();
  //   setData(seqData);
  // }

  // async function getChassisDeliveredData() {
  //   const delivData = await pickListDataPresenter.getChassisDeliveredData();
  //   setDeliveredData(delivData);
  // }

  // useEffect(() => {
  //   // if (!fileName) getFileNameFromDb();
  //   // else getProductionTargetSeqData();
  //   // getChassisDeliveredData();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="pick-list">
      <div className="pick-list__header w-100 d-flex justify-content-center my-3">
        <h1 className="fw-normal display-6">
          <span className="fw-bold">PICK LIST</span> App
        </h1>
      </div>
      <div className="pick-list__selections px-4">
        {/* <div className="d-flex justify-content-between align-items-center">
          <p
            className={`mb-0 ${
              fileName
                ? "pick-list__file-name fw-medium"
                : "fst-italic fw-light fs-5"
            }`}
          >
            {fileName ? fileName : "No file found"}
          </p>

          <div className="d-flex align-items-center gap-3">
            <input
              className="pick-list__upload-input form-control"
              type="file"
              onChange={handleFileChange}
            />
            <button className="btn blue-btn" onClick={handleSendFileToDb}>
              {isLoading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </div> */}
        <select
          className="line-select form-select mt-2"
          aria-label="Table Select"
          value={selectedOption}
          onChange={(event) => setSelectedOption(event.target.value)}
        >
          <option value="main-line">Main Line</option>
        </select>
      </div>
      <div className="pick-list__component d-flex justify-content-center w-100 px-4 mt-2 ">
        <div className="">
          <ProductionSequenceMaintain />
        </div>
        <div className="">
          <ProductionTargetSequence/>
        </div>
        <div className="">
          <ChassisToBeDelivered />
        </div>
      </div>
    </div>
  );
}
export default PickList;
