import {
  IBodyUploadFileUploadFilePost,
  IJobListModel,
  IMattressJobListModel,
} from "../../../generated/generated-proxies";
import server, { REACT_APP_API_BASE_URL } from "../../../generated/proxies";

export default class PickListServiceProvider {
  async uploadFileToDb(file: IBodyUploadFileUploadFilePost) {
    const response =
      await server.uploadDailyProdScheduleFile.uploadDailyProdScheduleFileUploadDailyProdScheduleFilePost(
        file,
        { baseURL: REACT_APP_API_BASE_URL }
      );
    return response.data.filename;
  }
  async getFileNameFromDb() {
    const response =
      await server.getDailyProdScheduleFilename.getDailyProdScheduleFilenameGetDailyProdScheduleFilenameGet(
        { baseURL: REACT_APP_API_BASE_URL }
      );
    return response.data.filename;
  }
  async getProductionTargetSeqFromFile() {
    const response =
      await server.getProductionTargetSequenceData.getProductionTargetSequenceDataGetProductionTargetSequenceDataGet(
        { baseURL: REACT_APP_API_BASE_URL }
      );
    return response.data.data;
  }
  async getChassisDeliveredData() {
    const response =
      await server.getChassisToDelivered.getChassisToDeliveredDataGetChassisToDeliveredGet(
        { baseURL: REACT_APP_API_BASE_URL }
      );
    return response.data;
  }
  async getMattressJobList() {
    const response =
      await server.getMattressJobList.getMattressJobListGetMattressJobListGet({
        baseURL: REACT_APP_API_BASE_URL,
      });
    return response.data;
  }
  async updateMattressJobList(
    mattressJobQuery: IMattressJobListModel
  ): Promise<string[]> {
    const response =
      await server.getAndSaveMattressJobList.getAndSaveMattressJobListGetAndSaveMattressJobListPost(
        mattressJobQuery,
        {
          baseURL: REACT_APP_API_BASE_URL,
        }
      );
    return response.data;
  }
  async updateItemssJobList(
    mattressJobQuery: IJobListModel
  ): Promise<string[]> {
    const response =
      await server.getAndSaveJobList.getAndSaveJobListGetAndSaveJobListPost(
        mattressJobQuery,
        {
          baseURL: REACT_APP_API_BASE_URL,
        }
      );
    return response.data;
  }
}
