import { TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { ITableColumn, ITableHeader } from '../../dailyReview-models';

const TableHeader = (props: ITableHeader) => {
    return (
            <TableHead>
              <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
                {
                    props.headers.map((item: ITableColumn, index) => (
                        <TableCell key={index} style={{ width: item.width }}>
                            <Typography variant="subtitle1" style={{ color: "white", fontFamily: "League Spartan, sans-serif" }}>
                                {item.col_name}
                            </Typography>
                        </TableCell>
                    ))
                }
              </TableRow>
        </TableHead>
    );
};

export default TableHeader;