import { Box, Grid, Typography } from "@mui/material";
import { DailyReviewPresenter } from "../dailyReview/dailyReview-presenter";
import { Fragment, useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import ChassisTable from "./ChassisTable";
import PrepDataTable from "./PrepDataTable";
import ProductionLineData from "./PrductionLineData";
import ChassisDeliveredTable from "./ChassisDeliveredTable";


export default function ProductionReview() {
    const axiosPrivate = useAxiosPrivate();
    let dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
    const [productionLineForMain, setProductionLineDataMain] = useState([]);
    const [productionLineForCRV, setProductionLineDataCRV] = useState([]);
    const [chassisDataForMain, setChassisDataForMain] = useState({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [chassisDataForCRV, setChassisDataForCRV] = useState({});
    const [prepData, setPrepData] = useState({});
    const lineVal = ['main', 'crv'];

    const fetchProductionLineData = async () => {
        try {
            const responseDataForMain = await dailyReviewPresenter.fetchProductionReviewData("main");
            
            const responseDataForCRV = await dailyReviewPresenter.fetchProductionReviewData("crv");
            setProductionLineDataMain(responseDataForMain);
            setProductionLineDataCRV(responseDataForCRV)
        } catch (error) {
            console.log("Error", error);
        }
    };

    const getChassisScheduledAndArrivedData = async () => {
      try {
        const chassisDataMain = await dailyReviewPresenter.getAllData(axiosPrivate, 'main-line');
        const chassisDataCRV = await dailyReviewPresenter.getAllData(axiosPrivate, 'crv-line');
        setChassisDataForMain(chassisDataMain);
        setChassisDataForCRV(chassisDataCRV);
      } catch (error) {
        console.log("Chassis Data error", error);
      }
    }

    const getAllPrepData = async () => {
      try {
        const prepData = await dailyReviewPresenter.getAllPrepData(axiosPrivate);
        setPrepData(prepData);
      } catch (error) {
        
      }
    }

    useEffect(() => {
      fetchProductionLineData();
      getChassisScheduledAndArrivedData();
      getAllPrepData();

      let id = setInterval(() => {
        fetchProductionLineData();
        getChassisScheduledAndArrivedData();
        getAllPrepData();
      }, 5 * 1000);
      return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const legends = [
      { label: 'Alert', color: 'red', border: '1px solid black'},
      { label: 'Work Active', color: 'orange',border: '1px solid black' },
      { label: 'Work Complete', color: 'green', border: '1px solid black' },
      { label: 'Stores', color: 'blue', border: '1px solid black' },
      { label: 'Planning', color: '#808080', border: '1px solid black' },
    ];

    return (
      <>
        <h1 className="text-start">CRUSADER PRODUCTION SYSTEM</h1>
        <Box className='mx-auto' sx={{ flexGrow: 1}} style={{maxWidth: '3800px'}}>
            <Grid item xs={12}>
                {
                  Object.keys(chassisDataForMain).map((rowName, i) => (
                      i === 0 ?
                      <ChassisDeliveredTable chassisData={chassisDataForMain} rowName={rowName} key={i} />
                      :
                      <ChassisTable chassisData={chassisDataForMain} rowName={rowName} key={i}/>
                  ))
                }
            </Grid>
            <Grid container spacing={3} className="mt-1">
                {
                  lineVal.map((line, index) => (
                    <Fragment key={index}>
                      {
                        Object.keys(prepData).map((row, i) => (
                          <Grid item xs={2} key={i}>
                            <PrepDataTable rowName={row} prepData={prepData} line={line}/>
                          </Grid>
                      ))
                    }
                    </Fragment>
                  ))
                }
          </Grid>
          <Grid container spacing={2} className="mt-1">
            <Grid item xs={7}>
                <ProductionLineData prodLineData={productionLineForMain} lineName="MAIN LINE" />
            </Grid>
            <Grid item xs={5}>
                <ProductionLineData prodLineData={productionLineForCRV} lineName="CRV LINE" />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className="my-3">
            {legends.map((legend, index) => (
              <Grid item key={index}>
                <Box display="flex" alignItems="center" marginRight="10px">
                  <Box
                    sx={{
                      width: 40,
                      height: 20,
                      backgroundColor: legend.color,
                      border: legend.border || 'none',
                      marginRight: 1,
                    }}
                  />
                  <Typography variant="body2">{legend.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
      
    );
}
