import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUserInfo } from "../store/actions";

const RequireAuth = ({ allowedRoles }: any) => {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const location = useLocation();
  return authInfo?.accessToken ? (
    <>
    <Outlet />
    </>
  ) : (
    <>
    <Navigate to="/" state={{ from: location }} replace />
    </>
  );
};

export default RequireAuth;
