import React, { useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
const { REACT_APP_API_WEBSOCKET_URL } = process.env;

const CycleTimer = ({ line }: { line: string }) => {
  const [count, setCount] = useState<number | null>(null);
  const axiosPrivate = useAxiosPrivate();
  const refresh = async()=>{
      await axiosPrivate.get(`refresh_main_crv_line_count_down/${line}`)
    }
  
  React.useEffect(() => {
    refresh();
  })
  React.useEffect(() => {
    // const ws = new WebSocket(`ws://${REACT_APP_API_BASE_URL_TWO}/ws/countdown/${line}`);
    const ws = new WebSocket(`${REACT_APP_API_WEBSOCKET_URL}/ws/timer/${line}`);
    ws.onmessage = (event) => {
      // console.log(event.data)
      let data = JSON.parse(event.data)
          console.log(line)
          if(data.line === line){
            setCount(data.value)
          }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center">
      <span style={{ paddingRight: "10px" }}>Cycle Time:</span>
      <div
        style={{
          textAlign: "center",
          border: "1px solid black",
          width: "30px",
          height: "30px",
          borderRadius: "15%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bolder",
          color: "#763497",
        }}
      >
        <span>{count !== null ? <div>{count}</div> : <div>0</div>}</span>
      </div>
    </div>
  );
};

export default CycleTimer;
